import { Browser } from "@capacitor/browser";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { logEvent } from "@src/appV2/lib/analytics";
import { openZendeskMessaging } from "@src/appV2/lib/ZendeskMessaging/openZendeskMessaging";
import { isZendeskMessagingUnread } from "@src/appV2/lib/ZendeskMessaging/utils";
import { USER_EVENTS } from "@src/constants";
import { SUPPORT_LINKS as DEFAULT_SUPPORT_LINKS } from "@src/constants/DEFAULT_SUPPORT_LINKS";
import { TERMS_OF_SERVICE_URL } from "@src/constants/urls";
import { book, chatboxEllipses, helpOutline, mail } from "ionicons/icons";
import { Fragment } from "react";

import "./style.scss";

declare global {
  interface Window {
    adaEmbed: any;
  }
}

export function NeedHelpPage() {
  // We both want a default value for the feature flag and for each individual key
  const supportLinks = {
    ...DEFAULT_SUPPORT_LINKS,
    ...useCbhFlag(CbhFeatureFlag.SUPPORT_LINKS, { defaultValue: DEFAULT_SUPPORT_LINKS }),
  };

  const openHelpCenter = () => {
    logEvent(USER_EVENTS.OPENED_HELP_CENTER);
    window.open(supportLinks.HELP_CENTER_WORKER);
  };

  const useZendesk = useCbhFlag(CbhFeatureFlag.USE_ZENDESK_OR_EMAIL, {
    defaultValue: true,
  });

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonTitle style={{ marginLeft: "8px" }}>Help Center</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem
            className="help-item"
            detail
            onClick={async () => await Browser.open({ url: TERMS_OF_SERVICE_URL })}
          >
            <IonIcon className="listIcons" icon={book} />
            <IonLabel>
              <p>The contractor role & how we work together</p>
              <h2>Terms of Service</h2>
            </IonLabel>
          </IonItem>
          <Fragment>
            {useZendesk ? (
              <IonItem
                className="help-item"
                onClick={async () => await openZendeskMessaging()}
                detail
              >
                <IonIcon className="listIcons" icon={chatboxEllipses} />
                <IonLabel>
                  <p>Contact via chat or request a callback</p>
                  <h2 className={isZendeskMessagingUnread() ? "notification-dot" : ""}>
                    Contact Support
                  </h2>
                </IonLabel>
              </IonItem>
            ) : (
              <IonItem className="help-item" href="mailto:support@clipboardhealth.com" detail>
                <IonIcon className="listIcons" icon={mail} />
                <IonLabel>
                  <p>Contact via email</p>
                  <h2>Email Support</h2>
                </IonLabel>
              </IonItem>
            )}
            <IonItem
              className="help-item"
              onClick={openHelpCenter}
              data-testid="open-help-center"
              detail
            >
              <IonIcon className="listIcons" icon={helpOutline} />
              <IonLabel>
                <p>Read help articles and contact us</p>
                <h2>Help Center</h2>
              </IonLabel>
            </IonItem>
          </Fragment>
        </IonList>
      </IonContent>
    </IonPage>
  );
}
