import { Browser } from "@capacitor/browser";
import { Text, Title } from "@clipboard-health/ui-react";
import { IonPage } from "@ionic/react";
import { Button, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { environmentConfig } from "@src/appV2/environment";
import { CbhFeatureFlag } from "@src/appV2/FeatureFlags";
import { useCbhFlag } from "@src/appV2/FeatureFlags";
import { AppBarHeader, BackButtonLink, PageWithHeader, useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useMutation } from "@tanstack/react-query";

import { generateDynamicLink, generatePaymentServiceOnboardingLink } from "./api";

export function StripeSetupPage() {
  const { showErrorToast } = useToast();

  const worker = useDefinedWorker();

  const stripeReturnLinkForceRedirect = useCbhFlag(
    CbhFeatureFlag.STRIPE_RETURN_LINK_FORCE_REDIRECT,
    {
      defaultValue: false,
    }
  );

  const { mutateAsync: generateStripeLink, isLoading: generateStripeLinkIsLoading } = useMutation({
    mutationFn: async (agentId: string) => {
      const redirectUrl = await generateDynamicLink(
        `${environmentConfig.REACT_APP_WEB_APP_URL}${DeprecatedGlobalAppV1Paths.OPEN_SHIFTS}`,
        stripeReturnLinkForceRedirect
      );

      return await generatePaymentServiceOnboardingLink(agentId, {
        refreshUrl: redirectUrl,
        returnUrl: redirectUrl,
      });
    },
  });

  useLogEffect(APP_V2_USER_EVENTS.VIEWED_STRIPE_SETUP);

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title="Setup Stripe"
            leftCta={
              <BackButtonLink
                alwaysUseDefaultBackTo
                defaultBackTo={DeprecatedGlobalAppV1Paths.ACCOUNT}
              />
            }
          />
        }
        footer={
          <Stack>
            <Button
              variant="contained"
              size="large"
              fullWidth
              disabled={generateStripeLinkIsLoading}
              onClick={async () => {
                try {
                  logEvent(APP_V2_USER_EVENTS.STARTED_STRIPE_SETUP, {
                    hcpID: worker.userId,
                  });

                  const linkToStripe = await generateStripeLink(worker.userId);

                  await Browser.open({ url: linkToStripe });
                } catch (error) {
                  showErrorToast(
                    "There was a problem while generating your Stripe sign-in link. Please try again"
                  );
                }
              }}
            >
              Set up your account
            </Button>
          </Stack>
        }
      >
        <Stack spacing={1}>
          <Title bold variant="h2" component="h2">
            Let’s connect your account so you can get paid
          </Title>
          <Text>We partner with Stripe to issue payments. Click below to set up your account.</Text>
          <Text sx={{ color: (theme) => theme.palette.primary.light }} bold>
            All debit cards and most bank account types are eligible for instant payouts!
          </Text>
        </Stack>
      </PageWithHeader>
    </IonPage>
  );
}
