import { isDefined } from "@clipboard-health/util-ts";
import { MINIMUM_TIME_FOR_MANDATORY_BREAK_IN_HOURS } from "@src/app/hcpShifts/constants";
import { FacilityNote } from "@src/appV2/Facilities/types";
import { WorkplaceNoteIdentifier } from "@src/appV2/redesign/Workplace/constants";
import { Shift } from "@src/lib/interface/src/lib/shift";
import { differenceInMinutes, parseISO } from "date-fns";

import { useIsSolveUnpaidBreaksEnabled } from "./useIsSolveUnpaidBreaksEnabled";

export interface UseIsBreakPolicyAcknowledgementRequiredProps {
  shift: Shift;
  facilityNotes: FacilityNote[];
}

export type BreakPolicyAcknowledgementRequired =
  | {
      isRequired: true;
      noteId: string;
      noteContent: string;
    }
  | {
      isRequired: false;
      noteId?: never;
      noteContent?: never;
    };

export const useIsBreakPolicyAcknowledgementRequired = (
  props: UseIsBreakPolicyAcknowledgementRequiredProps
): BreakPolicyAcknowledgementRequired => {
  const { shift, facilityNotes } = props;

  const isMandatoryBreakEnabled = shift.facility?.requiresLunchBreak ?? false;
  const isSolveUnpaidBreaksEnabled = useIsSolveUnpaidBreaksEnabled(shift);

  if (
    !isSolveUnpaidBreaksEnabled ||
    !isMandatoryBreakEnabled ||
    !isDefined(shift.end) ||
    !isDefined(shift.start) ||
    differenceInMinutes(parseISO(shift.end), parseISO(shift.start)) <=
      MINIMUM_TIME_FOR_MANDATORY_BREAK_IN_HOURS * 60
  ) {
    return {
      isRequired: false,
    };
  }

  const { noteId, note: noteContent } =
    facilityNotes.find(
      (note) => note.identifier === WorkplaceNoteIdentifier.MANDATORY_BREAK_POLICY
    ) ?? {};

  if (!isDefined(noteId) || !noteContent) {
    return {
      isRequired: false,
    };
  }

  return {
    isRequired: true,
    noteId,
    noteContent,
  };
};
